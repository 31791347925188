import React, { Component, useEffect } from "react";
import { Redirect, Route,Router } from "react-router";


import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import jQuery from "jquery"; ////I installed jquery using npm
import update from "react-addons-update";

import { data_dock_1 } from "../../Data/dock1.js";
import {
  PostData_owner_update_dock_prop,
  PostData_owner,
  PostData_owner_update,
  PostData_owner_dock_info,
  PostData_today_database,
  PostData_start_date_database,
  PostData_get_user_dokcs
} from "../../services/PostData";
//import {PostData_owner_1} from '../../services/PostData';

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: props.uesr_id,
      user_docks : [],
      username: "",
      password: "",
      loged_in: false,
      selectValue: "Select",
      dock_stat: [],
      today: "",
      selected_dock_id: -1,
      cur_date: 0,
      dock_info: [],
      dock_price: "0",
      loading : 1,
      today_db :"w",
      start_date_db :"w",
      Price: 0,
      Len: 0,
      Dep: 0,
      Wid: 0
    };
    this.read_owner_data = this.read_owner_data.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleClick_check = this.handleClick_check.bind(this);
    this.date_provider = this.date_provider.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.savebtn_dock_prop = this.savebtn_dock_prop.bind(this);
    this.read_owner_data();
    this.read_owner_data_dock_info = this.read_owner_data_dock_info.bind(this);
    this.read_today = this.read_today.bind(this);
    this.read_start_date = this.read_start_date.bind(this);
    this.set_user_dokcs = this.set_user_dokcs.bind(this);
    
    
  }
  componentDidMount() {
    this.set_user_dokcs();
    console.log(this.state.user_docks[0]);
  }
  componentDidUpdate(prevProps, prevState) {

    if (prevState.selected_dock_id != this.state.selected_dock_id) {
      
      console.log(this.state.user_docks[0][0]);
      this.read_owner_data();
      this.read_owner_data_dock_info();
      this.read_today();
      this.read_start_date();

      
    }


    if (prevState.dock_info != this.state.dock_info) {
      this.setState({ Price: this.state.dock_info[0].dock_price });
      this.setState({ Len: this.state.dock_info[0].len });
      this.setState({ Dep: this.state.dock_info[0].dep });
      this.setState({ Wid: this.state.dock_info[0].wid });
    }
  }
  set_user_dokcs()
  {
    
    PostData_get_user_dokcs(this.state.user_id).then((result) => {
      let response = result;
      
      this.setState({ user_docks: response });
      
     
    });
  }
  date_provider(number_plus) {
    //console.log(this.state.start_date_db);

    var today = new Date(this.state.start_date_db);
    //debugger; // from database
    //console.log("today");
    //console.log(today);
    today.setDate(today.getDate() + parseInt(number_plus));
    //console.log(today);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    //console.log(today);
    //console.log(number_plus);

    return today;
  }
  read_today()
  {
    PostData_today_database().then((result) => {
      let response = result;
      //console.log(response);
      
      this.setState({ today_db: response[0]['noww'] });
      
     
    });

  }
  read_start_date()
  {
    PostData_start_date_database().then((result) => {
      let response = result;
      //console.log(response);
      
      this.setState({ start_date_db: response[0]['noww'] });
     
    });
  }
  read_owner_data() {
    this.setState({loading : 0});
    PostData_owner(this.state).then((result) => {
      let response = result;
      //console.log(response);

      this.setState({ dock_stat: response });
      this.setState({loading : 1});
    });
  }

  read_owner_data_dock_info() {
    this.setState({loading : 0});
    PostData_owner_dock_info(this.state).then((result) => {
      let response = result;
      //console.log(response[0].dock_name);
      //console.log("dock info");
      this.setState({ dock_info: response });
      this.setState({loading : 1});
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(this.state.loged_in) SELECT DATEDIFF((SELECT value from variables where name_of_var = 'sd'), '2021-12-3') AS 'Result';
    console.log(this.state);
  }
  onSelect(e) {
    console.log(e.target.value);
  }
  onClick(e) {
    this.setState({ selectValue: e.target.id });
    console.log(e.target.attributes["x1"].value);
    this.setState({
      selected_dock_id: parseInt(e.target.attributes["x1"].value),
    });
    console.log(this.state.selected_dock_id);
  }

  handleClick_check(key) {
    console.log(this.state.dock_stat);

    const items = this.state.dock_stat;

    items[key]["booked_by_id"] = items[key]["booked_by_id"] == "0" ? "-1" : "0";
    this.setState({
      dock_stat: items,
    });

    console.log(this.dock_stat);
  }
  savebtn() {
    PostData_owner_update(this.state);
  }
  savebtn_dock_prop() {
    PostData_owner_update_dock_prop(this.state);
      
    //console.log(this.state.selected_dock_id+"---"+this.state.Price);  {window.location.reload()}
  }

  render() {
    return (
      <div>
      
      
        <div className="backg">
          <div className="container login-container pos-rel">
            <div className="row al">
              <div className="col-md-6 login-form-1 mid-marg dashboard-par">
                <div className="row">
                  <h3 style={{ margin: "auto" }}>Welcome</h3>
                  <p className="notif">-</p>
                </div>
                <div className="row">
                  <ul className="list-inline men">
                    <li className="list-inline-item men-item ">
                      <a className="men-a" href="#">
                        Home
                      </a>
                    </li>
                    <li className="list-inline-item men-item ">
                      <a className="men-a" href="#">
                        History
                      </a>
                    </li>
                    <li className="list-inline-item men-item ">
                      <a className="men-a" href="#">
                        Account Setting
                      </a>
                    </li>
                    <li className="list-inline-item men-item ">
                      <a className="men-a" href="#">
                        Log out
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="input-group input-group-lg">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text radious-left"
                          id="inputGroup-sizing-lg"
                        >
                          Select Dock:
                        </span>
                      </div>
                      <div className="dropdown radious-right">
                        <button
                          className="btn btn-secondary dropdown-toggle  radious-right"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {this.state.selectValue}
                        </button>
                        <ul
                          name="selectValue"
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          value={this.state.selectValue}
                          onSelect={this.onSelect}
                        >


                        {this.state.user_docks.map((data, key) => {
                          return (

                            <li onClick={this.onClick} value={data.Id}>
                            <a
                              className="dropdown-item"
                              x1={data.Id}
                              id={data.dock_name}
                              href="#"
                            >
                            {data.dock_name}
                            </a>
                          </li>

                          );
                        })}

                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm">
                    <div className="input-group input-group-lg">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text radious-left"
                          id="inputGroup-sizing-lg"
                        >
                          Dock Price ($):
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control text-up  sd text-box"
                        value={this.state.Price}
                        name="Price"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm" style={{ textAlign: "end" }}>
                    <label
                      className="btn btn-success radious-right"
                      onClick={this.savebtn_dock_prop}
                    >
                      Save Dock
                    </label>
                  </div>
                </div>

                <div
                  className="row row-bordered"
                  style={{ paddingTop: "1rem" }}
                >
                  <div className="col-sm">
                    <div className="input-group input-group-lg">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text radious-left"
                          id="inputGroup-sizing-lg"
                        >
                          Length (ft):
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control text-up  sd text-box"
                        value={this.state.Len}
                        name="Len"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="input-group input-group-lg">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text radious-left"
                          id="inputGroup-sizing-lg"
                        >
                          Depth (ft):
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control text-up  sd text-box"
                        value={this.state.Dep}
                        name="Dep"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="input-group input-group-lg">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text radious-left"
                          id="inputGroup-sizing-lg"
                        >
                          Width (ft):
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control text-up  sd text-box"
                        value={this.state.Wid}
                        name="Wid"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-">
                  <p className="pading-30">Next 16-day Schedule</p>
                  {this.state.loading == 0 ? <p className="p-status">Loading Data...</p> :""}
                  
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Select</th>

                          <th scope="col">Date</th>
                          <th scope="col">Free/Booked</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dock_stat.slice(0, 8).map((data, key) => {
                          return (
                            <tr
                              x={data.Id}
                              key={data.Id}
                              style={{
                                backgroundColor:
                                  data.booked_by_id == 0 ? "rgb(118 202 118)" : "rgb(239 130 130)",
                              }}
                            >
                              <td scope="col">
                                <input
                                  className="form-check-input me-1 "
                                  type="checkbox"
                                  value=""
                                  disabled={
                                    data.booked_by_id != 0 &&
                                    data.booked_by_id != -1
                                      ? "disabled"
                                      : ""
                                  }
                                  style={{
                                    backgroundColor:
                                      data.booked_by_id == 0 ||
                                      data.booked_by_id == -1
                                        ? "rgb(118 202 118)"
                                        : "rgb(239 130 130)",
                                  }}
                                  ///{data.booked_by_id == 0 ? "green" :"red"}
                                  checked={
                                    data.booked_by_id != 0 ? "checked" : ""
                                  }
                                  onClick={() => this.handleClick_check(key)}
                                />
                              </td>

                              <td>{this.date_provider(data.date)}</td>
                              <th>
                                {data.booked_by_id == 0
                                  ? "Free"
                                  : data.booked_by_id == -1
                                  ? "Locked"
                                  : "Booked"}
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-sm-6">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Select</th>

                          <th scope="col">Date</th>
                          <th scope="col">Free/Booked</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dock_stat.slice(8, 16).map((data, key) => {
                          return (
                            <tr
                              x={data.Id}
                              key={data.Id}
                              style={{
                                backgroundColor:
                                data.booked_by_id == 0 ? "rgb(118 202 118)" : "rgb(239 130 130)",
                              }}
                            >
                              <td scope="col">
                                <input
                                  className="form-check-input me-1 "
                                  type="checkbox"
                                  value=""
                                  disabled={
                                    data.booked_by_id != 0 &&
                                    data.booked_by_id != -1
                                      ? "disabled"
                                      : ""
                                  }
                                  style={{
                                    backgroundColor:
                                      data.booked_by_id == 0 ||
                                      data.booked_by_id == -1
                                        ? "rgb(118 202 118)"
                                        : "rgb(239 130 130)",
                                  }}
                                  ///{data.booked_by_id == 0 ? "green" :"red"}
                                  checked={
                                    data.booked_by_id != 0 ? "checked" : ""
                                  }
                                  onClick={() =>
                                    this.handleClick_check(key + 8)
                                  }
                                />
                              </td>

                              <td>{this.date_provider(data.date)}</td>
                              <th>
                                {data.booked_by_id == 0
                                  ? "Free"
                                  : data.booked_by_id == -1
                                  ? "Locked"
                                  : "Booked"}
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-sm-3">
                  <label
                    style={{ width: "100%" }}
                    className="btn btn-success position-relative"
                    onClick={this.savebtn}
                  >
                    Save
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="not-show">
          <h2>Owner Dashboard</h2>
          <div
            className="row"
            style={{ backgroundColor: "lightblue", padding: "4%" }}
          >
            <div className="col-sm-3">
              <label className="btn btn-primary position-relative">Home</label>
            </div>
            <div className="col-sm-3">
              <label className="btn btn-primary position-relative">
                History
              </label>
            </div>
            <div className="col-sm-3">
              <label className="btn btn-primary position-relative">
                Account Setting
              </label>
            </div>
            <div className="col-sm-3">
              <label className="btn btn-primary position-relative">
                Log out
              </label>
            </div>
          </div>
          <div
            className="row"
            style={{ backgroundColor: "lightblue", padding: "4%" }}
          >
            <div className="col-sm-3">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Selet Dock
                </button>
                <ul
                  name="selectValue"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                  value={this.state.selectValue}
                  onSelect={this.onSelect}
                >
                  <li onClick={this.onClick} value="0">
                    <a className="dropdown-item" x1="0" id="Dock 0" href="#">
                      Dock 0
                    </a>
                  </li>
                  <li onClick={this.onClick} value="1">
                    <a className="dropdown-item" x1="1" id="Dock 1" href="#">
                      Dock 1
                    </a>
                  </li>
                  <li onClick={this.onClick} value="2">
                    <a className="dropdown-item" x1="2" id="Dock 2" href="#">
                      Dock 2
                    </a>
                  </li>
                </ul>
              </div>
              <label className="">Selected Dock :</label>
              <label className="">{this.state.selectValue}</label>
            </div>
          </div>

          <div className="row" style={{ padding: "2%" }}>
            <div className="row" style={{ padding: "2%" }}>
              <h2 className="col-3">Next 30-day Schedule</h2>
              <div className="col-sm-3">
                <label
                  className="btn btn-primary position-relative"
                  onClick={this.savebtn}
                >
                  Save
                </label>
              </div>
            </div>
            <ul className="list-group  list-group-horizontal">
              <li className="list-group-item ">
                <input
                  className="form-check-input me-1 "
                  type="checkbox"
                  value=""
                />
                8/29/2021
              </li>
              <li
                className="list-group-item"
                style={{ backgroundColor: "red" }}
              >
                Occupied By :{" "}
              </li>
            </ul>

            {this.state.dock_stat.map((data, key) => {
              return (
                <ul
                  className="list-group  list-group-horizontal"
                  x={data.Id}
                  key={data.Id}
                >
                  <li className="list-group-item ">
                    <input
                      className="form-check-input me-1 "
                      type="checkbox"
                      value=""
                      disabled={
                        data.booked_by_id != 0 && data.booked_by_id != -1
                          ? "disabled"
                          : ""
                      }
                      style={{
                        backgroundColor:
                          data.booked_by_id == 0 || data.booked_by_id == -1
                            ? "green"
                            : "red",
                      }}
                      ///{data.booked_by_id == 0 ? "green" :"red"}
                      checked={data.booked_by_id != 0 ? "checked" : ""}
                      onClick={() => this.handleClick_check(key)}
                    />
                    {this.date_provider(data.date)}
                  </li>
                  <li
                    class="list-group-item"
                    style={{
                      backgroundColor:
                        data.booked_by_id == 0
                          ? "green"
                          : data.booked_by_id == -1
                          ? "blue"
                          : "red",
                    }}
                  >
                    {data.booked_by_id}{" "}
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default UserDashboard;
