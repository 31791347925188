import React, { Component, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min.js";
import 'leaflet/dist/leaflet.css';
import $ from "jquery";
import jQuery from "jquery"; ////I installed jquery using npm
import update from "react-addons-update";
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import L from 'leaflet'
import { data_dock_1 } from "../../Data/dock1.js";
import {
  PostData_owner,
  PostData_owner_update,
  PostData_customer_available_query,
  PostData_customer_book,
} from "../../services/PostData";
//import "leaflet"
import { MapContainer,TileLayer,Marker,Popup, Map} from 'react-leaflet/cjs';

class UserDashboardU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loged_in: false,
      selectValue: "",
      dock_stat: [],
      today: "",
      selected_dock_id: -1,
      cur_date: 0,
      startDate: new Date(),
      endDate: new Date(),
      startDate_searched: new Date(),
      endDate_searched: new Date(),
      dock_availaible: [],
      fixed_today: new Date(),
      diffDays_global: 0,
      option_selected: 0,
      user_id_now: this.props.uesr_id,
      selsected_dock_book: -1,
      full_screen_map :0,
      show_contact : 0,
      loading : 1,
      position : [29.5392, -94.7630386353]
      
        };
    this.read_owner_data = this.read_owner_data.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleClick_check = this.handleClick_check.bind(this);
    this.date_provider = this.date_provider.bind(this);
    this.savebtn = this.savebtn.bind(this);
    this.read_owner_data();
    this.diff_date = this.diff_date.bind(this);
    this.get_resulte_available = this.get_resulte_available.bind(this);
    this.calc_total_price = this.calc_total_price.bind(this);
    this.full_screen_map_change = this.full_screen_map_change.bind(this);
    this.contact_container_change = this.contact_container_change.bind(this);
    L.Icon.Default.imagePath='img/';
    

    this.mapRef = React.createRef();
    this.mapRef2 = React.createRef();
    
   


  

    
  }

 

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_dock_id != this.state.selected_dock_id) {
      this.read_owner_data();
    }
    this.mapRef.current.invalidateSize(true);
    




  }
contact_container_change()
  {
    if(this.state.show_contact == 0)
    { this.setState({show_contact :  1 });}
    else
    {
      this.setState({show_contact :  0 });
    }
   
  }

  full_screen_map_change()
{
 // this.mapRef.current.invalidateSize();
 // this.mapRef.current.invalidateSize(true);
  
 
console.log(this.mapRef);


  //const myMap = L.map('map').setView([37.7749, -122.4194], 13);
  if(this.state.full_screen_map == 0)
  { this.setState({full_screen_map :  1 });}
  else
  {
    this.setState({full_screen_map :  0 });
  }
 
  console.log(this.state.full_screen_map);

}

  date_provider(number_plus) {
    var today = new Date();
    console.log("sadasd");
    console.log(today);
    today.setDate(today.getDate() + parseInt(number_plus));
    console.log(today);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    console.log(today);
    console.log(number_plus);

    return today;
  }
  read_owner_data() {
    PostData_owner(this.state).then((result) => {
      let response = result;
      console.log(response);

      this.setState({ dock_stat: response });
    });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(this.state.loged_in)
    console.log(this.state);
  }
  onSelect(e) {
    console.log(e.target.value);
  }
  onClick(e) {
    this.setState({ selectValue: e.target.id });
    console.log(e.target.attributes["x1"].value);
    this.setState({
      selected_dock_id: parseInt(e.target.attributes["x1"].value),
    });
    console.log(this.state.selected_dock_id);
  }
  handleClick_check(key,data) {
    console.log(key);
    this.setState({ selsected_dock_book: key });
    this.setState({position : [data.latitude,data.longitude]});
    console.log("location");
    console.log(this.state.position);
    //items[key]["booked_by_id"] = items[key]["booked_by_id"] == "0" ? "-1" : "0" ;
    // this.setState({
    //    dock_stat: items
    //  });

    // console.log(this.dock_stat);
  }
  savebtn() {
    //PostData_owner_update(this.state.startDate);
    //console.log(this.state.dock_availaible);

    var today = this.state.fixed_today;
    var diffTime = Math.abs(this.state.startDate - today);
    //console.log(today);
    var diffDays = 0;
    if (diffTime < 100000) {
      diffDays = 0;
    } else {
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    console.log(diffDays + " in");

    var duration = this.diff_date(this.state.startDate, this.state.endDate);

    console.log(this.state.user_id_now + " current user");
    var new_array = [...this.state.dock_availaible];
    new_array[0].ss = "asdasda";
    console.log(new_array);

    PostData_customer_book(
      diffDays,
      duration,
      this.state.dock_availaible[this.state.selsected_dock_book]["dock_id"],
      this.state.user_id_now
    ).then((result) => {
      let response = result;
      console.log(response);
      //this.setState({dock_availaible : response});
    });
  }
  handleDateSelect(date) {}
  diff_date(a, b) {
    const date1 = a;
    const date2 = b;
    const diffTime = Math.abs(date2 - date1);
    var diffDays = 0;
    if (diffTime < 1000) {
      diffDays = 0;
    } else {
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    //console.log(diffTime + " milliseconds");
    //console.log(diffDays + " days");

    return diffDays;
  }
  calc_total_price(daily_price) {
    var dp = parseFloat(daily_price);
    var duration = parseFloat(
      this.diff_date(this.state.startDate_searched, this.state.endDate_searched)
    );
    var tot = parseFloat(dp * duration);
    console.log(tot.toFixed(2));
    return tot.toFixed(2);
  }
  get_resulte_available(a) {
    this.unCheck_for_serach();
    this.setState({startDate_searched : this.state.startDate});
    this.setState({endDate_searched : this.state.endDate});
    
    
    this.setState({loading : 0});
    this.setState({ selsected_dock_book: -1 });

    var today = this.state.fixed_today;
    var diffTime = Math.abs(a - today);
    //console.log(today);
    var diffDays = 0;
    if (diffTime < 100000) {
      diffDays = 0;
    } else {
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    console.log(diffDays + " in");

    var duration = this.diff_date(this.state.startDate, this.state.endDate);
    console.log(duration);

    PostData_customer_available_query(diffDays, duration).then((result) => {
     
      console.log("here");
      console.log(this.state.loading);
      let response = result;
      console.log(response);
      this.setState({ dock_availaible: response });
      this.setState({loading : 1});
    });

    console.log(this.state.dock_availaible);
    this.mapRef2.current.setView(this.state.position,9);
  }
  unCheck(key,data) {
    var x = jQuery(".checking");
    for(var i=0; i<=x.length; i++) {
       
      jQuery(x[i]).prop('checked', false);
        console.log("ke");
        console.log(key);
     }
     jQuery(x[key]).prop('checked', true);
     this.handleClick_check(key,data);
     this.mapRef2.current.setView([data.latitude,data.longitude],13);
  }
unCheck_for_serach()
{
  var x = jQuery(".checking");
  for(var i=0; i<=x.length; i++) {
     
    jQuery(x[i]).prop('checked', false);
   }
}
  render() {
    const position = [29.5392, -94.7630386353];
    const position2 = [29.6862, -95.0255355835];
    return (



      
      <div>
      <div className="backg">
      <div className="container login-container pos-rel">
      
        <div className="row al">
        
          <div className="col-md-6 login-form-1 mid-marg dashboard-par">
          
            <div className="row bigmap" style={{ display: this.state.full_screen_map ==0 ? "none" : "block" }}>
  
            <MapContainer
            whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
            center={position} zoom={9} scrollWheelZoom={false} style={{ height: '70vh', width: '100%' }} name="map2" id="map1">
          
            <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.state.dock_availaible.map((data, key) => {
            return (      
              <Marker position={[data.latitude, data.longitude]}>
              <Popup>
              {data.dock_name} <br />$ {this.calc_total_price(data.dock_price) }
              </Popup>
            </Marker>);
          })}

        </MapContainer>
        <label
        className="btn btn-primary position-relative"
       onClick={this.full_screen_map_change}
      >
        Close FullScreen Map
      </label>

            </div>
            <div className="row contact-info" style={{ display: this.state.show_contact ==0 ? "none" : "block" }}>
            <div className="contact-container">
            <p className="p-title-contact">Booking { this.state.selsected_dock_book != -1 ? this.state.dock_availaible[this.state.selsected_dock_book]["dock_name"] : "" } For { this.state.selsected_dock_book != -1 ? parseFloat(this.diff_date(this.state.startDate, this.state.endDate)) : "" } Day(s) For $ { this.state.selsected_dock_book != -1 ? this.calc_total_price(this.state.dock_availaible[this.state.selsected_dock_book]["dock_price"]) : "" }  </p>
            
            
            
            
            <p className="p-data-contact"><span className="title-info">Phone Number:</span> { this.state.selsected_dock_book != -1 ? this.state.dock_availaible[this.state.selsected_dock_book]["dock_phone"] : "" }</p>
            <p className="p-data-contact"> <span className="title-info">E-Mail:</span> { this.state.selsected_dock_book != -1 ? this.state.dock_availaible[this.state.selsected_dock_book]["dock_email"] : "" }</p>
            <p className="p-data-contact"> <span className="title-info">Address:</span> { this.state.selsected_dock_book != -1 ? this.state.dock_availaible[this.state.selsected_dock_book]["dock_address"] : "" }</p>



            </div>
            <label
            style={{width:"100%"}}
            className="btn btn-primary position-relative"
           onClick={this.contact_container_change}
          >
            Back
          </label>

            </div>



            <div className="panel" style={{ display: this.state.full_screen_map ==0 && this.state.show_contact == 0 ? "block" : "none" }}>
            <div className="row">
            
            <h3 style={{ margin: "auto" }}>Welcome</h3>
            <p className="notif">-</p>
              <ul className="list-inline men">
                <li className="list-inline-item men-item ">
                  <a className="men-a" href="#">
                    Home
                  </a>
                </li>
                <li className="list-inline-item men-item ">
                  <a className="men-a" href="#">
                    History
                  </a>
                </li>
                <li className="list-inline-item men-item ">
                  <a className="men-a" href="#">
                    Account Setting
                  </a>
                </li>
                <li className="list-inline-item men-item ">
                  <a className="men-a" href="#">
                    Log out
                  </a>
                </li>
              </ul>
            </div>
            <div className="row row-bordered">
              <div className="col-sm-4">
                <div className="input-group input-group-lg">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text radious-left"
                      id="inputGroup-sizing-lg"
                    >
                      Select Start Date:
                    </span>
                  </div>
                  
                  <ReactDatePicker className="btn btn-secondary dropdown-toggle  radious-right"
                 
                  selected={this.state.startDate}
                  onChange={(date) => this.setState({ startDate: date })}
                />
                <div>
                <label className="notif">Selected start date :</label>
                <label className="notif">{this.state.startDate.toDateString()}</label>
                </div>
                  
                  
                  
                  
                  
                  </div>
              </div>
              <div className="col-sm-4">
              <div className="col-sm-2">
              </div>
              <div className="col-sm-2"></div>
              
              <div className="input-group input-group-lg">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text radious-left"
                    id="inputGroup-sizing-lg"
                  >
                    Select End Date:
                  </span>
                </div>
                
                <ReactDatePicker
                className="btn btn-secondary dropdown-toggle  radious-right"
                selected={this.state.endDate}
                onChange={(date) => this.setState({ endDate: date })}
              />
              <div>
              <label className="notif">Selected end date :</label>
              <label className="notif">{this.state.endDate.toDateString()}</label>
              </div>
                
                
                
                
                
                </div>
            </div>
              <div className="col-sm-2" style={{display : this.state.startDate > this.state.endDate ? "none" : "block"} } >
              
              <label className="notif notify-bigfnt">Duration: </label>
              <label className="notif notify-bigfnt">
                {" "}
                {this.diff_date(this.state.startDate, this.state.endDate)} days
              </label>


             
              <div className="col-sm-2"></div>

              </div>
              <div className="col-sm-2" style={{display : this.state.startDate <= this.state.endDate ? "none" : "block"} } >
              
              <label className="notif notify-bigfnt" style= {{fontWeight: "600"}}> Select Correct Dates ! </label>
          


             
              <div className="col-sm-2"></div>

              </div>
            
              <div className="col-sm-2"> 
              <label
              className="btn btn-primary position-relative"
              style={{width : "100%"}}
              onClick={(a, b) =>
                this.get_resulte_available(this.state.startDate)
              }
            >
              Search
            </label>
              
              
              </div>
              <div className="col-sm-">
              <p className="pading-30">Available Option(s)</p>
              {this.state.loading == 0 ? <p className="p-status">Loading Data...</p> :""}
              </div>
            </div>

            <div className="row">
            <div className="col-sm-8">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">select</th>
                  <th scope="col">Dock ID</th>
                  <th scope="col">Dock Name</th>
                  <th scope="col">Owner</th>
                  <th scope="col">Daily Price</th>
                  <th scope="col">Total Price</th>
                  <th scope="col">Length(ft)</th>
                  <th scope="col">Depth(ft)</th>
                  <th scope="col">Width(ft)</th>
                 
                </tr>
              </thead>
              <tbody>
                {this.state.dock_availaible.map((data, key) => {
                  return (
                    <tr x={data.Id} key={data.Id}>
                      <td scope="col">
                        {" "}
                        <input
                          className="form-check-input me-1 checking"
                          
                          type="checkbox"
                          value=""
                          style={{
                            backgroundColor: "green",
                            fontSize: "1.5rem",
                          }}
                          onClick={() => this.handleClick_check(key,data) , ()=> this.unCheck(key,data)}
                        />{" "}
                      </td>
                      <th scope="row">{data.dock_id}</th>
                      <td>{data.dock_name}</td>
                      <td>{data.acc_name}</td>
                      <td>$ {data.dock_price}</td>
                      <td>$ {this.calc_total_price(data.dock_price)}</td>
                      <td>{data.len}</td>
                      <td>{data.dep}</td>
                      <td>{data.wid}</td>
                    
                    </tr>
                  );
                })}
              </tbody>
            </table>
                <div className="ro">
                <div className="col-sm-3">
                <label
                  className="btn btn-primary position-relative"
                  //onClick={this.savebtn}
                  onClick={this.contact_container_change}
                >
                  Book Now !
                </label>
              </div></div>
            </div>
          <div className="col-sm-4">
          
          <MapContainer
          whenCreated={ mapInstance => { this.mapRef2.current = mapInstance } }
           center={this.state.position} zoom={9} scrollWheelZoom={false} style={{ height: '44vh', width: '100%' }} name="map1" id="map2">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {this.state.dock_availaible.map((data, key) => {
            return (      
              <Marker position={[data.latitude, data.longitude]}>
              <Popup>
              {data.dock_name} <br />$ {this.calc_total_price(data.dock_price) } 
              </Popup>
            </Marker>);
          })}

        </MapContainer>
        <label
        className="btn btn-primary position-relative"
        style = {{width:"100%"}}
        onClick={this.full_screen_map_change}
      >
        Open FullScreen Map
      </label>
           </div>
       
                 </div>

            </div>
        </div>
      </div>
    </div>
    </div>








        </div>
    );
  }
}

export default UserDashboardU;
