import logo from './logo.svg';
import './App.css';
import Login from './Components/login/login';
import UserDashboard from './Components/userDashboard/userDashboard';
import React, { useState } from "react";
import UserDashboardU from './Components/userDashboardU/userDashboardU';

function App() {
  const [childData, setChildData] = useState("ss");
  const [userID, setuserID] = useState("ss");
  const [Loged, setLoged] = useState(false);
  //setChildData("asd"); child
  return (
    <div>
   
    {Loged ? "" : <Login passChildData={setChildData} passuserID={setuserID}  passLoged = {setLoged}/> }
    
   
    
    {Loged && childData == 1 ? <UserDashboard uesr_id={userID}/> : <div/> }
    {Loged && childData == 2 ? <UserDashboardU uesr_id={childData} /> : <div/> }
    </div>
  );
}

export default App;
