import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";

import "./login.css";
import "bootstrap/dist/css/bootstrap.css";
import { PostData } from "../../services/PostData";
import UserDashboard from "../userDashboard/userDashboard";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loged_in: false,
      parent_status: props.passChildData,
      parent_userID: props.passuserID,
      parent_status_loged: props.passLoged,
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  login() {
    PostData(this.state).then((result) => {
      let response = result;
      console.log(response);
      //console.log(this.state.parent_status);
      this.state.parent_status("changed by child");
      console.log(this.state.loged_in);
      if (response.id > 0) {
        //console.log(response);
        this.state.parent_status(response.typeofuser);
        this.state.parent_userID(response.id);
        console.log(response.id);

        this.setState({ loged_in: true });
        console.log(this.state.loged_in);
        console.log("this.state.loged_in");
        this.state.parent_status_loged("true");
      }
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(this.state.loged_in)
    console.log(this.state);
  }
  render() {
    return (
      <Router>
        <div className="backg">
          <div className="container login-container pos-rel">
            <div className="row al">
              <div className="col-md-6 login-form-1 mid-marg">
                <h3>Airbnb Waterway Project</h3>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control text-up"
                    placeholder="Your Email *"
                   
                    name = "username"
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control text-up"
                    placeholder="Your Password *"
                    name="password"
                    onChange={this.onChange}
                 
                  />
                </div>
                <div className="form-group">
                  <input type="submit" className="btnSubmit" value="Login" onClick={this.login}/>
                </div>
                <div className="form-group row last-it">
                <div className="col-md-6">
                  <a href="#" class="btnForgetPwd red-text">
                    Forget Password?
                  </a>
                  </div>
                  <div className="col-md-6 txt-right">
                  <a href="#" class="btnForgetPwd red-text">
                  Sign-Up
                </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="not-show">
        <div
          className="row"
          style={{ backgroundColor: "lightblue", padding: "4%" }}
        >
          <div className="col-sm-2">
            <label>Username</label>
            <input
              type="text"
              name="username1"
              placeholder="Username"
              onChange={this.onChange}
            />
          </div>
          <div className="col-sm-2">
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.onChange}
            />
          </div>
          <div className="col-sm-2">
            <input
              type="submit"
              value="Login"
              className="button"
              onClick={this.login}
            />
          </div>
          <div className="col-sm-2">
            <label name="result" placeholder="Username">
              {this.state.loged_in ? "loged in" : "not loged in"}
            </label>
          </div>
        </div>

        <div style={{ backgroundColor: "lightblue", paddingBottom: "4%" }}>
          <div className="row">
            <div
              className="hedder"
              style={{
                backgroundColor: "lightblue",
                paddingBottom: "2%",
                paddingRight: "0px",
              }}
            >
              <div className="col-xs-6">
                <h1>Airbnb Waterway Project</h1>
                <h2>log-in page</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <form>
              <div className="form-group col-xs-3">
                <label for="exampleInputEmail1">Username</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Username"
                ></input>
                <small id="emailHelp" className="form-text text-muted"></small>
              </div>
              <div className="form-group col-xs-3">
                <label for="exampleInputEmail1">Password</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Password"
                ></input>
                <small id="emailHelp" className="form-text text-muted"></small>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="cc">
              <div className="col-xs-3">
                <input
                  type="submit"
                  value="Login"
                  className="btn btn-primary"
                  onClick={this.login}
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        </Router>
    );
  }
}

export default Login;
