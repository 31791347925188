
export function PostData_owner_update_dock_prop(userData)
{
    let BaseUrl = "localhost"
    return new Promise((resolve,reject)=>{
        //fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=7&dock_id='+userData.selected_dock_id+'&Price='+userData.Price+'&len='+userData.Len+'&dep='+userData.Dep+'&wid='+userData.Wid,{
        
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=7&dock_id='+userData.selected_dock_id+'&Price='+userData.Price+'&len='+userData.Len+'&dep='+userData.Dep+'&wid='+userData.Wid,{
            method : 'get', 
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}



export function PostData(userData)
{
    let BaseUrl = "localhost"
  //  console.log(userData);
    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=1&user='+userData.username+'&pass='+userData.password,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_owner(userData)
{
    let BaseUrl = "localhost"
    console.log("udataaaa");
    console.log(userData);
   //debugger;
    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=2?&dock_id='+userData.selected_dock_id+'&cur_date='+userData.cur_date,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_owner_dock_info(userData)
{
    let BaseUrl = "localhost"
    //console.log("posteted");
   
    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=6?&dock_id='+userData.selected_dock_id,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}


export function PostData_owner_update(userData)
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
   // console.log(userData);

    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=3?&dock_id='+userData.selected_dock_id+'&json_updated='+JSON.stringify(userData.dock_stat),{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_customer_available_query(startdate,duration)
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
  //  console.log(startdate);
  //  console.log(duration);
  //  console.log("this");

    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=4?&startdate='+startdate+'&duration='+duration,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_customer_book(startdate,duration,dock_id,current_user_id)
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
    //console.log(startdate);
    //console.log(duration);
    //console.log("this");

    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=5&startdate='+startdate+'&duration='+duration+'&dock_id='+dock_id+'&current_user_id='+current_user_id,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_today_database()
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
    //console.log(startdate);
    //console.log(duration);
    //console.log("this");

    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=8',{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}

export function PostData_start_date_database()
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
    //console.log(startdate);
    //console.log(duration);
    //console.log("this");

    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=9',{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}



export function PostData_get_user_dokcs($user_id)
{
    
    //make a json out of state variable and send to api !
    let BaseUrl = "localhost"
    //console.log(startdate);
    //console.log(duration);
    //console.log("this");
    
    return new Promise((resolve,reject)=>{
        fetch('https://lamarhamidi.com/lamarapi/index.php?api_code=10&user_id='+$user_id,{
            method : 'get', // seemingly worked with POST method Also !
        
            //body : JSON.stringify(userData)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        }).catch((er) =>{
            console.log(er)
        });
        
    });
    
}




